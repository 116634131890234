"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergePaymentSettings = void 0;
var mergePaymentSettings = function (acceptBlockSettings, paymentSettings, qwilrPayEnabled) {
    var _a;
    if (qwilrPayEnabled === void 0) { qwilrPayEnabled = true; }
    return __assign(__assign({}, acceptBlockSettings), { payNow: {
            enabled: Boolean((paymentSettings === null || paymentSettings === void 0 ? void 0 : paymentSettings.enabled) && qwilrPayEnabled),
            enableOptionalPayment: !Boolean((_a = paymentSettings === null || paymentSettings === void 0 ? void 0 : paymentSettings.requireOnAccept) !== null && _a !== void 0 ? _a : true),
            enablePartPayment: Boolean(paymentSettings === null || paymentSettings === void 0 ? void 0 : paymentSettings.partialPaymentConfig.enabled),
            partPaymentType: (paymentSettings === null || paymentSettings === void 0 ? void 0 : paymentSettings.partialPaymentConfig.kind) || "percentage",
            partPaymentAmount: (paymentSettings === null || paymentSettings === void 0 ? void 0 : paymentSettings.partialPaymentConfig.amount) || 0,
            payLaterButtonText: paymentSettings === null || paymentSettings === void 0 ? void 0 : paymentSettings.payLaterButtonText,
        } });
};
exports.mergePaymentSettings = mergePaymentSettings;
